import ServiceCompatible from "@/components/sections/ServiceCompatible";
import HomeBackGroundImage from "@/img/home-background.png";
import HomeBackGround2Image from "@/img/image31.png";
import GlobeImage from "@/img/image35.jpg";
import modalAnimationData from "@/img/lottie_tracking_anim.json";
import Counter from "@cospex/client/components/Counter";
import FeaturesList from "@cospex/client/components/FeaturesList";
import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import SearchPhone from "@cospex/client/components/SearchPhone";
import useAuth from "@cospex/client/hooks/useAuth";
import { useTitle } from "@cospex/client/hooks/useTitle";
import useTranslation from "@cospex/client/hooks/useTranslation";
import HomepageGraphic from "@cospex/client/tracker/components/HomepageGraphic";
import { HomeCard, Section } from "@cospex/client/tracker/components/Layout";
import LocateCard from "@cospex/client/tracker/components/Locate";
import Pricing from "@cospex/client/tracker/components/Pricing";
import Application from "@cospex/client/tracker/components/icons/Application";
import DeviceIcon from "@cospex/client/tracker/components/icons/Device";
import Network from "@cospex/client/tracker/components/icons/Network";
import Phone from "@cospex/client/tracker/components/icons/Phone";
import HowTo from "@cospex/client/tracker/components/sections/HowTo";
import PhonesLocated from "@cospex/client/tracker/components/sections/PhonesLocated";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Home({ title }: { title: string | ReactElement }) {
  const { t } = useTranslation();
  useTitle(t("home-page-title"));
  const { session } = useAuth();
  const navigate = useNavigate();

  const onTrack = () => {
    if (session) {
      navigate("/dashboard");
      return;
    }
    navigate("/payment");
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("phoneNumber")!.focus();
    }, 0);
  }, []);

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${HomeBackGroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "380%", md: "150%" },
          pt: {
            md: 16,
            xs: 12,
          },
          color: "white",
          position: "relative",
        }}
      >
        <Section>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{ fontSize: { xs: "25px", sm: "50px" } }}
                  gutterBottom
                >
                  {title} <Box component="span">{t("home-title-addendum")}</Box>
                </Typography>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  {t("home-description")}
                </Typography>
                <SearchPhone
                  onTrack={onTrack}
                  business="tracker"
                  modalAnimationData={modalAnimationData}
                />
                <FeaturesList
                  featureKeys={[t("home-features-1"), t("home-features-2")]}
                />
                <Counter sx={{ color: "black" }}>
                  {t("home-numbers-identified")}
                </Counter>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginX: "auto",
                    aspectRatio: "812/864",
                    maxWidth: 400,
                  }}
                >
                  <HomepageGraphic backgroundDisabled fillColor="#1355FF" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Box>
      <Container
        sx={{
          mt: {
            xs: 0,
            sm: 4,
            md: 6,
          },
        }}
      >
        <ReviewCarousel />
      </Container>
      <Section>
        <Stack alignItems="center" mt="2rem">
          <Typography variant="h2" textAlign="center">
            {t("home-locate-title")}
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-evenly",
            columnGap: "2.5em",
            rowGap: "2.5em",
            paddingTop: "3rem",
          }}
        >
          <LocateCard
            Icon={Phone}
            title={t("home-locate-love-title")}
            desc={t("home-locate-love-desc")}
          />
          <LocateCard
            Icon={Phone}
            title={t("home-locate-stolen-title")}
            desc={t("home-locate-stolen-desc")}
          />
          <LocateCard
            Icon={Phone}
            title={t("home-locate-lost-title")}
            desc={t("home-locate-lost-desc")}
          />
        </Stack>
      </Section>
      <Box bgcolor="primary.main" color="white">
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Box
            component="img"
            flex="1 1 0"
            sx={{
              width: { sx: "100%", md: "50%" },
              height: "auto",
            }}
            src={GlobeImage}
          />
          <Stack
            flex="1 1 0"
            sx={{ justifyContent: "center", rowGap: "1rem", py: "3rem" }}
          >
            <HomeCard
              Icon={DeviceIcon}
              title={t("home-globe-phones-title")}
              desc={t("home-globe-phones-desc")}
              variant="h4"
            />
            <HomeCard
              Icon={Network}
              title={t("home-globe-operators-title")}
              desc={t("home-globe-operators-desc")}
              variant="h4"
            />
            <HomeCard
              Icon={Application}
              title={t("home-globe-applications-title")}
              desc={t("home-globe-applications-desc")}
              variant="h4"
            />
          </Stack>
        </Stack>
      </Box>

      <PhonesLocated />
      <Container>
        <HowTo />
      </Container>
      <Box
        sx={{
          backgroundImage: `url(${HomeBackGround2Image})`,
        }}
      >
        <ServiceCompatible />
      </Box>
      <Section>
        <Stack alignItems="center">
          <Pricing />
        </Stack>
      </Section>
    </Box>
  );
}
