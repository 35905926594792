import Header from "@cospex/client/components/Header";
import Footer from "@cospex/client/tracker/components/Footer";
import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

export default function App() {
  const location = useLocation();
  const isHomeOrLocalizaPage =
    location.pathname === "/" || location.pathname.startsWith("/localiza");

  return (
    <Box>
      <Header
        menu={{
          dashboardHref: "/dashboard/sms",
        }}
        sx={isHomeOrLocalizaPage ? { mb: -13 } : {}}
      />
      <Outlet />
      <Footer />
    </Box>
  );
}
